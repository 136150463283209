<template>
  <section id="news">
    <div id="news_title">
      <div class="ja-title">最新の投稿</div>
      <h1 class="en-title">News</h1>
    </div>
    <div id="news-container" v-show="!loading">
      <div v-for="(news, i) in all_news" :key="i" class="each-news-container">
        <EachNews :news="news" />
      </div>
    </div>
    <div id="news-loading" v-show="loading">
      <img src="/assets/images/loading.gif" />
    </div>
    <button id="news_button" @click="moreNews()" v-if="more_news_button">
      もっと見る
    </button>
  </section>
</template>
<script>
import { db } from "../plugins/firebase";
import { defineAsyncComponent } from "vue";
const LoadingComponent = {
  template: '<img src="/assets/images/loading.gif" />',
};
const ErrorComponent = {
  template: "<p>エラー</p>",
};
const EachNews = defineAsyncComponent({
  loader: () => import("@/components/EachNews.vue"),
  delay: 200,
  timeout: 3000,
  errorComponent: ErrorComponent,
  loadingComponent: LoadingComponent,
});
export default {
  name: "Top",
  components: {
    EachNews,
  },
  data() {
    return {
      all_news: [],
      limit_num: 30,
      more_news_button: false,
      loading: true,
    };
  },
  created() {
    this.fetchNews();
  },
  methods: {
    fetchNews() {
      try {
        this.loading = true;
        const fetched_news = [];
        let news_count = 0;
        db.collection("news")
          .where("status", "==", 1) // 公開のNewsのみ
          .limit(this.limit_num)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              news_count++;
              const news = {
                id: doc.data().id,
                image: doc.data().thumbnail_url,
                date: doc.data().newsed_date,
                tag: doc.data().tag,
                title: doc.data().title,
              };
              fetched_news.push(news);
            });
            if (this.limit_num > 30) {
              // moreNewsからfetchNewsが呼ばれた場合、既存のNews部分だけ取り除く
              fetched_news.splice(0, this.limit_num - 30);
            }
            // 既存のNewsに取得したnewsを結合
            this.all_news = this.all_news.concat(fetched_news);
            if (news_count % 30 !== 0) {
              // 新たに取得したNewsが30件以下の場合、もっと見るボタン非表示にする
              this.more_news_button = false;
            } else {
              this.more_news_button = true;
            }
          });
      } catch (error) {
        console.log(error);
        alert("Newsの取得に失敗しました");
      } finally {
        this.loading = false;
      }
    },
    moreNews() {
      this.limit_num += 30;
      this.fetchNews();
    },
  },
};
</script>
<style lang="scss" scoped>
//投稿表示ページのレイアウト
#news {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  @include flex-column(center, flex-start);
  padding-bottom: 50px;
  &_title {
    color: $main2-color;
    margin: 6vh 0;
    div {
      letter-spacing: 0.1vw;
      font-weight: bold;
      font-size: 15px;
    }
    h1 {
      letter-spacing: 0.1vw;
      font-size: 50px;
      font-weight: 900;
      margin: 0.5vh 0 0 0;
    }
  }
  &_contents {
    width: 80%;
    @include flex-row(space-between, normal);
  }
  &_button {
    margin-top: 5vh;
    padding: 15px 40px;
    font-weight: bold;
    font-size: 15px;
    background-color: transparent;
    border: 3px solid;
    color: #0c0454;
    border-radius: 30px;
    outline: none;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
    &:active {
      opacity: 0.4;
    }
  }
  #news-container {
    width: 70%;
    @include flex-row(space-between, flex-start);
    flex-wrap: wrap;
    .each-news-container {
      width: 33%;
      @include flex-row(center, center);
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 1024px) {
    #news-container {
      width: 100%;
      @include flex-row(center, flex-start);
      flex-wrap: wrap;
      .each-news-container {
        width: 30%;
        @include flex-row(center, center);
        margin: 40px;
      }
    }
  }

  @media screen and (max-width: 599px) {
    #news-container {
      width: 100%;
      @include flex-row(center, flex-start);
      flex-wrap: wrap;
      .each-news-container {
        width: 100%;
        @include flex-row(center, center);
        margin: 15px 40px;
      }
    }
  }

  #news-loading {
    width: 70%;
    height: 55vh;
    @include flex-column(center, center);
    img {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
