<template>
  <section id="signin">
    <h1>Signin</h1>
    <div id="signin-form">
      <input type="email" v-model="email" placeholder="Emailを入力" />
      <input type="password" v-model="password" placeholder="Passwordを入力" />
      <div id="submit-container">
        <button type="button" @click="signin()">Signin</button>
      </div>
    </div>
  </section>
</template>

<script>
import firebase from "@/plugins/firebase.js";
import store from "@/store/index.js";
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  mounted() {
    if (store.getters.getToken) {
      this.$router.push("/admin");
    }
  },
  methods: {
    async signin() {
      if (this.email === "" || this.email === null) {
        alert("メールアドレスが未入力です");
        return;
      }
      if (this.password === "" || this.password === null) {
        alert("パスワードが未入力です");
        return;
      }
      const result = await firebase.signIn(this.email, this.password);
      if (result.status) {
        alert("SignInに成功しました");
        location.reload();
      } else {
        alert(result.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#signin {
  width: 100%;
  height: 100vh;
  padding-bottom: 100px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  @include flex-column(center, center);
  #signin-form {
    width: 40%;
    @include flex-column(center, center);
    input {
      width: 80%;
      padding: 5px 10px;
      margin: 20px;
    }
    #submit-container {
      width: 90%;
      @include flex-row(flex-end, center);
      button {
        background-color: transparent;
        padding: 10px 20px;
        cursor: pointer;
        border-width: 1px;
      }
    }
  }
}
</style>
