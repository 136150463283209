<template>
  <section id="top">
    <div id="main">
      <section id="home">
        <div id="home_video">
          <div id="home_video_header"></div>
          <div id="home_video_inner">
            <iframe
              src="https://www.youtube.com/embed/WukpHHLLF6M?autoplay=1&mute=1&playsinline=1&loop=1&playlist=WukpHHLLF6M&controls=0&disablekb=1"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div id="home_video_footer"></div>
        </div>
        <div id="home_mob_image">
          <img src="/assets/images/TOP.jpg" alt="トップ画像" />
        </div>
        <div id="home_message">
          <div id="home_message_title">
            <img src="/assets/images/mogakitanosime.png" alt="もがき楽しめ" />
          </div>
          <div id="home_message_english" class="en-title">
            When you hit the wall,<br class="mob_br" />
            You get the chance to make breakthrough.
          </div>
        </div>
      </section>
      <section class="ja-title" id="self">
        <div id="self_top">
          <div class="ja-title" id="self_title_sub">自己紹介</div>
          <div class="en-title" id="self_title">About</div>
        </div>
        <div id="self_contents">
          <div id="self_documents">
            <div id="self_second_name">山岳ランナー</div>
            <div id="self_name">上正原 真人</div>
            <div id="self_detail_document">
              <p>
                人生もレースもトレーニングも<br
                  class="mob_br"
                />思い描いたようにはいかないもの。
              </p>
              <p>でもそんな時こそ成長できるチャンス。</p>
              <p>
                壁にぶち当たった時、<br
                  class="mob_br"
                />その瞬間を楽しめる人間になりたい。
              </p>
            </div>
            <button v-on:click="linkToProfile" id="self_button">
              もっと見る
            </button>
          </div>
        </div>
      </section>
      <section id="result">
        <div id="result_top">
          <div class="ja-title" id="result_title_sub">過去の成績</div>
          <div class="en-title" id="result_title">Result</div>
        </div>
        <div id="result_main">
          <img
            src="/assets/images/Kpjt_top_result.jpg"
            alt="過去の成績"
            id="result_main_image"
          />
          <img
            src="/assets/images/TOP_result_iPhone.svg"
            alt="過去の成績"
            id="result_main_mob_image"
          />
        </div>
      </section>
      <section id="movie">
        <div id="movie_content">
          <div id="movie_document">
            <div id="movie_sub_name" class="ja-title">メッセージ</div>
            <div id="movie_name" class="en-title">Message</div>
          </div>
          <div id="movie_link">
            <div id="movie_inner">
              <iframe
                id="movie_iframe"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/aWFMtYyi4Sk"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section id="post">
        <div id="post_title">
          <div class="ja-title">ニュース</div>
          <h1 class="en-title">News</h1>
        </div>
        <div id="news-container">
          <div
            v-for="(news, index) in all_news"
            :key="index"
            class="each-news-container"
          >
            <EachNews :news="news" />
          </div>
        </div>
        <router-link to="/news" id="post_button"> もっと見る </router-link>
      </section>
      <section id="sponsor">
        <div id="sponsor_top">
          <div id="sponsor_title_sub" class="ja-title">スポンサー</div>
          <div id="sponsor_title" class="en-title">Sponsor</div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { db } from "../plugins/firebase";
import { defineAsyncComponent } from "vue";
const LoadingComponent = {
  template: '<img src="/assets/images/loading.gif" />',
};

const ErrorComponent = {
  template: "<p>エラー</p>",
};
const EachNews = defineAsyncComponent({
  loader: () => import("@/components/EachNews.vue"),
  delay: 200,
  timeout: 3000,
  errorComponent: ErrorComponent,
  loadingComponent: LoadingComponent,
});
export default {
  name: "Top",
  components: {
    EachNews,
  },
  data() {
    return {
      all_news: [],
    };
  },
  created() {
    this.fetchNews();
  },
  methods: {
    fetchNews() {
      try {
        db.collection("news")
          .where("status", "==", 1) // 公開のNewsのみ
          .limit(3)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              const news = {
                id: doc.data().id,
                image: doc.data().thumbnail_url,
                date: doc.data().posted_date,
                tag: doc.data().tag,
                title: doc.data().title,
              };
              this.all_news.push(news);
            });
          });
      } catch (error) {
        console.log(error);
        alert("Newsの取得に失敗しました");
      }
    },
    linkToProfile() {
      this.$router.push("/profile");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/stylesheets/Top/top-pc.scss";
@import "src/assets/stylesheets/Top/top-tab.scss";
@import "src/assets/stylesheets/Top/top-mobile.scss";
</style>
