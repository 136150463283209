<template>
  <section id="settings">
    <div id="signout-container">
      <button @click="signOut()">SignOut</button>
    </div>
  </section>
</template>
<script>
import firebase from "@/plugins/firebase.js";
export default {
  methods: {
    async signOut() {
      const check = confirm("本当にサインアウトしてもいいですか？？");
      if (!check) {
        return;
      }
      const isSignOut = await firebase.signOut();
      if (isSignOut.status) {
        location.reload();
      } else {
        alert(isSignOut.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#settings {
  width: 100%;
  height: 100vh;
  @include flex-column(center, center);
  #signout-container {
    button {
      background-color: red;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
    }
  }
}
</style>
