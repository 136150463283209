<template>
  <div class="calendar">
    <div v-if="showModal" id="calendar_mob_detail">
      <div id="calendar_mob_detail_close" class="close">
        <div v-on:click="closeModal">×</div>
      </div>
      <div id="calendar_mob_detail_content">
        <div class="mob_detail_header">
          <div class="mob_detail_date">
            <div v-if="sameDate(clickedEventDetail.showEvent[eventIndex])">
              {{ eventDate(clickedEventDetail.showEvent[eventIndex].start) }}
            </div>
            <div v-else>
              <div class="mob_detail_date_up">
                {{ eventDate(clickedEventDetail.showEvent[eventIndex].start) }}
              </div>
              <div class="mob_detail_date_down">
                ~ {{ eventDate(clickedEventDetail.showEvent[eventIndex].end) }}
              </div>
            </div>
          </div>
          <div class="detail_event_arrow">
            <div
              class="fas fa-angle-left fa-2x"
              v-if="eventIndex !== 0"
              @click="eventIndex -= 1"
            ></div>
            <div
              class="fas fa-angle-right fa-2x"
              v-if="eventIndex !== clickedEventDetail.showEvent.length - 1"
              @click="eventIndex += 1"
            ></div>
          </div>
        </div>
        <div class="mob_detail_content">
          <div class="mob_detail_title ja-title">
            {{ clickedEventDetail.showEvent[eventIndex].title }}
          </div>
          <div
            class="mob_detail_desc"
            v-if="clickedEventDetail.showEvent[eventIndex].description"
            v-html="clickedEventDetail.showEvent[eventIndex].description"
          ></div>
          <div class="mob_detail_info">
            <div class="mob_detail_time">
              <div><i class="far fa-clock"></i></div>
              <div
                class="mob_detail_time_same"
                v-if="sameDate(clickedEventDetail.showEvent[eventIndex])"
              >
                {{ eventTimeSame(clickedEventDetail.showEvent[eventIndex]) }}
              </div>
              <div class="mob_detail_time_same" v-else>
                <div class="mob_detail_time_same_up">
                  {{
                    eventTimeDiffStart(
                      clickedEventDetail.showEvent[eventIndex].start,
                      clickedEventDetail.showEvent[eventIndex].end
                    )
                  }}
                </div>
                <div class="mob_detail_time_same_up">
                  {{
                    eventTimeDiffEnd(
                      clickedEventDetail.showEvent[eventIndex].start,
                      clickedEventDetail.showEvent[eventIndex].end
                    )
                  }}
                </div>
              </div>
            </div>
            <div
              class="mob_detail_location"
              v-if="clickedEventDetail.showEvent[eventIndex].location"
            >
              <div><i class="fas fa-map-marker-alt"></i></div>
              <div class="mob_detail_location_title">
                <a
                  :href="clickedEventDetail.showEvent[eventIndex].location"
                  target="_blank"
                  >詳細</a
                >
              </div>
            </div>
            <div
              class="mob_detail_link"
              v-if="clickedEventDetail.showEvent[eventIndex].link"
            >
              <div class="fas fa-external-link-square-alt"></div>
              <div class="mob_detail_link_title">
                <a
                  :href="clickedEventDetail.showEvent[eventIndex].link"
                  target="_blank"
                  >詳細リンク</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="title">
      <div id="title_name_sub" class="ja-title">カレンダー</div>
      <div id="title_name" class="en-title">Calendar</div>
    </div>
    <div class="calendar_underline"></div>
    <div class="calendar_content">
      <div class="calendar_title">
        <div
          class="fas fa-angle-left fa-2x calendar_title_button"
          @click="prevMonth"
        ></div>
        <h2 class="calendar_title_text">{{ calendarTitle }}</h2>
        <i
          class="fas fa-angle-right fa-2x calendar_title_button"
          @click="nextMonth"
        ></i>
      </div>
      <div id="loading" v-if="loading">
        <img src="/assets/images/loading.gif" />
      </div>
      <div class="calendar_sheet" id="sheet" v-else>
        <div class="calendar_main">
          <div class="calendar_dayOfWeek_row">
            <div
              v-for="(day, index) in dayOfWeek"
              :key="index"
              class="calendar_dayOfWeek"
            >
              <div class="calendar_dayOfWeek_inner">
                {{ day }}
              </div>
            </div>
          </div>
          <div
            v-for="(week, index) in calendars"
            :key="index"
            class="calendar_daily_borad"
          >
            <div
              v-for="(day, index) in week"
              :key="index"
              @click="clickDay(day)"
              class="calendar_daily"
              :class="{ calendar_daily_outside: currentMonth !== day.month }"
            >
              <div class="calendar_day">
                <div v-if="day.isClicked" class="calendar_day_clicked">
                  <div class="calendar_day_inner">
                    {{ day.dateNum }}
                  </div>
                </div>
                <div v-else class="calendar_day_noclicked">
                  <div class="calendar_day_inner">
                    {{ day.dateNum }}
                  </div>
                </div>
              </div>
              <div v-for="event in day.event" :key="event.id">
                <div
                  v-if="event.width"
                  class="calendar_event"
                  :style="`width:${event.width}%;
                  background-color:${event.bgColor};
                  color:${event.textColor}`"
                >
                  <div class="calendar_event_title">{{ event.title }}</div>
                </div>
                <div v-else class="calendar_event"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="calendar_details"
          :style="{ height: calendarHeight + 'px' }"
        >
          <div
            class="detail_container"
            v-if="clickedEventDetail !== `カレンダーをクリックしてください`"
          >
            <div class="detail_contents">
              <div class="detail_event">
                <div class="detail_event_header">
                  <div class="detail_event_date">
                    <div
                      class="detail_event_date"
                      v-if="sameDate(clickedEventDetail.showEvent[eventIndex])"
                    >
                      {{
                        eventDate(
                          clickedEventDetail.showEvent[eventIndex].start
                        )
                      }}
                    </div>
                    <div class="detail_event_date" v-else>
                      <div class="detail_event_date_up">
                        {{
                          eventDate(
                            clickedEventDetail.showEvent[eventIndex].start
                          )
                        }}
                      </div>
                      <div class="detail_event_date_down">
                        ~
                        {{
                          eventDate(
                            clickedEventDetail.showEvent[eventIndex].end
                          )
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="detail_event_arrow">
                    <div
                      class="fas fa-angle-left fa-2x"
                      v-if="eventIndex !== 0"
                      @click="eventIndex -= 1"
                    ></div>
                    <div
                      class="fas fa-angle-right fa-2x"
                      v-if="
                        eventIndex !== clickedEventDetail.showEvent.length - 1
                      "
                      @click="eventIndex += 1"
                    ></div>
                  </div>
                </div>
                <div
                  class="detail_event_category"
                  v-for="(category, index) in clickedEventDetail.showEvent[
                    eventIndex
                  ].categories"
                  :key="`categories:${index}`"
                >
                  <div class="detail_event_category_title">
                    {{ category }}
                  </div>
                </div>
                <div class="detail_event_title ja-title">
                  {{ clickedEventDetail.showEvent[eventIndex].title }}
                </div>
                <div
                  class="detail_event_desc"
                  v-if="clickedEventDetail.showEvent[eventIndex].description"
                  v-html="clickedEventDetail.showEvent[eventIndex].description"
                ></div>
                <div class="detail_event_info">
                  <div class="detail_event_time">
                    <div class="fas fa-clock"></div>
                    <div
                      class="detail_event_time_same"
                      v-if="sameDate(clickedEventDetail.showEvent[eventIndex])"
                    >
                      {{
                        eventTimeSame(clickedEventDetail.showEvent[eventIndex])
                      }}
                    </div>
                    <div class="detail_event_time_same" v-else>
                      <div class="detail_event_time_same_up">
                        {{
                          eventTimeDiffStart(
                            clickedEventDetail.showEvent[eventIndex].start,
                            clickedEventDetail.showEvent[eventIndex].end
                          )
                        }}
                      </div>
                      <div class="detail_event_time_same_up">
                        {{
                          eventTimeDiffEnd(
                            clickedEventDetail.showEvent[eventIndex].start,
                            clickedEventDetail.showEvent[eventIndex].end
                          )
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="detail_event_location"
                    v-if="clickedEventDetail.showEvent[eventIndex].location"
                  >
                    <div class="fas fa-map-marker-alt"></div>
                    <div class="detail_event_location_title">
                      <a
                        :href="
                          clickedEventDetail.showEvent[eventIndex].location
                        "
                        target="_blank"
                        >詳細</a
                      >
                    </div>
                  </div>
                  <div
                    class="detail_event_link"
                    v-if="clickedEventDetail.showEvent[eventIndex].link"
                  >
                    <div class="fas fa-external-link-square-alt"></div>
                    <div class="detail_event_link_title">
                      <a
                        :href="clickedEventDetail.showEvent[eventIndex].link"
                        target="_blank"
                        >詳細リンク</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="detail_container">
            <div class="detail_contents">{{ clickedEventDetail }}</div>
          </div>
        </div>
      </div>
    </div>
    <button v-on:click="linkToContact" class="calendar_button">
      お仕事の依頼はこちらから
    </button>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { functions } from "../../src/plugins/firebase";

export default {
  data() {
    return {
      calendars: [],
      eventBgColor: ["lavender", "palegreen", "lemonchiffon"],
      eventTextColor: ["slateblue", "limegreen", "goldenrod"],
      events: [],
      currentDate: dayjs(),
      dayOfWeek: ["日", "月", "火", "水", "木", "金", "土"],
      calendarHeight: 0,
      clickedEventDetail: "カレンダーをクリックしてください",
      eventIndex: 0,
      loading: false,
      isMobile: false,
    };
  },
  computed: {
    calendarTitle() {
      return this.currentDate.format("YYYY[年] M[月]");
    },
    currentMonth() {
      return this.currentDate.format("YYYY-MM");
    },
    sortedEvents() {
      return this.events.slice().sort(function (a, b) {
        let startDate = dayjs(a.start).format("YYYY-MM-DD");
        let endDate = dayjs(b.start).format("YYYY-MM-DD");
        if (startDate < endDate) return -1;
        if (startDate > endDate) return 1;
        return 0;
      });
    },
    eventDate() {
      const dayOfWeek = this.dayOfWeek;
      return function (date) {
        const dayjsDate = dayjs(date);
        return `${dayjsDate.get("month") + 1}/${dayjsDate.get("date")} (${
          dayOfWeek[dayjsDate.get("day")]
        })`;
      };
    },
    sameDate() {
      return function (event) {
        const startTime = dayjs(event.start).format("YYYY-MM-DD");
        const endTime = dayjs(event.end).format("YYYY-MM-DD");
        return startTime === endTime;
      };
    },
    //開始日と終了日が同日の場合の表示形式
    eventTimeSame() {
      return function (event) {
        const startTime = dayjs(event.start);
        const endTime = dayjs(event.end);
        if (
          startTime.format("HH:mm") === "00:00" &&
          endTime.format("HH:mm") === "00:00"
        ) {
          return "終日";
        } else {
          return `${startTime.format("HH:mm")} ~ ${endTime.format("HH:mm")}`;
        }
      };
    },
    //開始日と終了日が違う場合の表示形式(開始日)
    eventTimeDiffStart() {
      return function (start, end) {
        const startDate = dayjs(start);
        const endDate = dayjs(end);
        if (
          startDate.format("HH:mm") === "00:00" &&
          endDate.format("HH:mm") === "00:00"
        ) {
          return `【${startDate.get("month") + 1}/${startDate.get(
            "date"
          )}】 終日`;
        } else {
          return `【${startDate.get("month") + 1}/${startDate.get(
            "date"
          )}】 ${startDate.format("HH:mm")} ~`;
        }
      };
    },
    //開始日と終了日が違う場合の表示形式(終了日)
    eventTimeDiffEnd() {
      return function (start, end) {
        const startDate = dayjs(start);
        const endDate = dayjs(end);
        if (
          startDate.format("HH:mm") === "00:00" &&
          endDate.format("HH:mm") === "00:00"
        ) {
          return `【${endDate.get("month") + 1}/${endDate.get("date")}】 終日`;
        } else {
          return `【${endDate.get("month") + 1}/${endDate.get(
            "date"
          )}】 ~ ${endDate.format("HH:mm")}`;
        }
      };
    },
    showModal() {
      return (
        this.clickedEventDetail !== "カレンダーをクリックしてください" &&
        this.isMobile
      );
    },
  },
  created() {
    this.executeFetchGoogleCalenderData(this.currentMonth);
  },
  mounted() {
    window.addEventListener("resize", this.judgeMobile);
    this.judgeMobile();
  },
  methods: {
    judgeMobile() {
      this.isMobile = window.innerWidth <= 1024;
    },
    getStartDate() {
      let date = dayjs(this.currentDate);
      date = date.startOf("month");
      const youbiNum = date.day();
      return date.subtract(youbiNum, "days");
    },
    getEndDate() {
      let date = dayjs(this.currentDate);
      date = date.endOf("month");
      const youbiNum = date.day();
      return date.add(6 - youbiNum, "days");
    },
    getCalendar() {
      let startDate = this.getStartDate();
      const endDate = this.getEndDate();
      const weekNumber = Math.ceil(endDate.diff(startDate, "days") / 7);
      this.calendarHeight = 126 * weekNumber + 30;
      this.calendars = [];
      for (let week = 0; week < weekNumber; week++) {
        let weekRow = [];
        for (let day = 0; day < 7; day++) {
          const event = this.getDayEvent(startDate, startDate.day());
          const showEvent = this.getDayDetailEvent(startDate);
          weekRow.push({
            dateNum: startDate.get("date"),
            month: startDate.format("YYYY-MM"),
            date: startDate.format("YYYY-MM-DD"),
            day: startDate.day(),
            isClicked: false,
            event,
            showEvent,
          });
          startDate = startDate.add(1, "days");
        }
        this.calendars.push(weekRow);
      }
    },
    nextMonth() {
      this.currentDate = dayjs(this.currentDate).add(1, "month");
      this.executeFetchGoogleCalenderData(this.currentMonth);
    },
    prevMonth() {
      this.currentDate = dayjs(this.currentDate).subtract(1, "month");
      this.executeFetchGoogleCalenderData(this.currentMonth);
    },
    //detailに表示するイベントを取得
    getDayDetailEvent(date) {
      let dayShowEvents = [];
      this.sortedEvents.forEach((event) => {
        let startDate = dayjs(event.start).format("YYYY-MM-DD");
        let endDate = dayjs(event.end).format("YYYY-MM-DD");
        let nowDate = date.format("YYYY-MM-DD");
        if (startDate <= nowDate && endDate >= nowDate) {
          dayShowEvents.push(event);
        }
      });
      return dayShowEvents;
    },
    //カレンダーに表示するイベントを取得
    getDayEvent(date, day) {
      let stackIndex = 0;
      let dayEvents = [];
      let startedEvents = [];
      this.sortedEvents.forEach((event) => {
        let startDate = dayjs(event.start).format("YYYY-MM-DD");
        let endDate = dayjs(event.end).format("YYYY-MM-DD");
        let nowDate = date.format("YYYY-MM-DD");
        if (startDate <= nowDate && endDate >= nowDate) {
          if (startDate === nowDate) {
            [stackIndex, dayEvents, startedEvents] = this.getStackEvents(
              event,
              day,
              date,
              stackIndex,
              dayEvents,
              startedEvents,
              event.start
            );
          } else if (day === 0) {
            [stackIndex, dayEvents, startedEvents] = this.getStackEvents(
              event,
              day,
              date,
              stackIndex,
              dayEvents,
              startedEvents,
              date
            );
          } else {
            startedEvents.push(event);
          }
        }
      });
      return dayEvents;
    },
    getEventWidth(end, start, day) {
      let betweenDays = dayjs(end).diff(dayjs(start), "days");
      if (betweenDays > 6 - day) {
        return (6 - day) * 100 + 85;
      } else {
        return betweenDays * 100 + 85;
      }
    },
    clickDay(clickedDay) {
      this.calendars.forEach((week) => {
        week.forEach((day) => {
          if (clickedDay !== day) {
            day.isClicked = false;
          }
        });
      });
      clickedDay.isClicked = !clickedDay.isClicked;
      if (clickedDay.isClicked && clickedDay.showEvent.length > 0) {
        this.eventIndex = 0;
        this.clickedEventDetail = clickedDay;
      } else {
        this.initClickedEventDetail();
      }
    },
    initClickedEventDetail() {
      this.clickedEventDetail = "カレンダーをクリックしてください";
    },
    getStackEvents(
      event,
      day,
      calendarDate,
      stackIndex,
      dayEvents,
      startedEvents,
      start
    ) {
      [stackIndex, startedEvents, dayEvents] = this.getStartedEvents(
        event,
        stackIndex,
        startedEvents,
        dayEvents
      );
      let width = this.getEventWidth(event.end, start, day);
      Object.assign(event, {
        stackIndex,
      });
      dayEvents.push({ ...event, width });
      stackIndex++;
      return [stackIndex, dayEvents, startedEvents];
    },
    getStartedEvents(event, stackIndex, startedEvents, dayEvents) {
      let startedEvent;
      do {
        startedEvent = startedEvents.find(
          (event) => event.stackIndex === stackIndex
        );
        if (startedEvent) {
          dayEvents.push(startedEvent);
          stackIndex++;
        }
      } while (typeof startedEvent !== "undefined");
      return [stackIndex, startedEvents, dayEvents];
    },
    async executeFetchGoogleCalenderData(date) {
      this.loading = true;
      this.events = [];
      this.initClickedEventDetail();
      const date_str = date ? date : dayjs().format("YYYY-MM");
      const fetchGoogleCalenderData = functions.httpsCallable(
        "fetchGoogleCalenderData"
      );
      const res = await fetchGoogleCalenderData({ date: date_str });
      let colorIndex = 0;
      res.data.items?.forEach((event) => {
        this.events.push({
          id: event.id,
          title: event.summary,
          categories: null,
          description: event.description || null,
          start: event.start.date || event.start.dateTime,
          end:
            event.end.dateTime ||
            dayjs(event.end.date).subtract(1, "date").format("YYYY-MM-DD"),
          location: event.location || null,
          link: null,
          bgColor: this.eventBgColor[colorIndex],
          textColor: this.eventTextColor[colorIndex],
        });
        if (colorIndex === 2) {
          colorIndex = 0;
        } else {
          colorIndex += 1;
        }
      });
      this.getCalendar();
      this.loading = false;
    },
    linkToContact() {
      this.$router.push("/contact");
    },
    closeModal() {
      this.clickedEventDetail = "カレンダーをクリックしてください";
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/stylesheets/Calendar/calendar-pc.scss";
@import "src/assets/stylesheets/Calendar/calendar-tab.scss";
@import "src/assets/stylesheets/Calendar/calendar-mobile.scss";
</style>
