export default {
  methods: {
    async getReq(url, property) {
      try {
        await this.axios.get(url, property);
      } catch (error) {
        this.errorHundler(error);
      }
    },
    async postReq(url, property) {
      try {
        await this.axios.post(url, property);
      } catch (error) {
        this.errorHundler(error);
      }
    },
    async patchReq(url, property) {
      try {
        await this.axios.patch(url, property);
      } catch (error) {
        this.errorHundler(error);
      }
    },
    async deleteReq(url, property) {
      try {
        await this.axios.delete(url, property);
      } catch (error) {
        this.errorHundler(error);
      }
    },
    errorHundler(error) {
      console.log(error);
      throw error.response.status;
    },
  },
};
