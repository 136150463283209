<template>
  <section id="article-index">
    <h1 id="title">記事一覧</h1>
    <div id="article-container" v-if="selected_articles">
      <div id="article-num">
        <span>{{ selected_articles.length }}記事</span>
      </div>
      <div id="filter">
        <div
          class="filter-name"
          :class="{ active: active_filter === 0 }"
          @click="active_filter = 0"
        >
          すべて
        </div>
        <div
          class="filter-name"
          :class="{ active: active_filter === 1 }"
          @click="active_filter = 1"
        >
          公開中
        </div>
        <div
          class="filter-name"
          :class="{ active: active_filter === 2 }"
          @click="active_filter = 2"
        >
          下書き
        </div>
      </div>
      <div id="sort-property-container">
        <span id="sort-property" @click="sort_property = !sort_property">
          並び替え
        </span>
        <span
          id="sort-property-icon"
          :class="{ up: sort_property, down: !sort_property }"
          >&ensp;</span
        >
        <div id="sort-property-pulldown-container" v-show="sort_property">
          <div id="sort-propert-pulldown-inner">
            <div id="sort-propert-pulldown">
              <div @click="setIsDescending(true)" class="sort">
                <span v-if="is_descending === true">✔</span>新しい順
              </div>
              <div @click="setIsDescending(false)" class="sort">
                <span v-if="is_descending === false">✔</span>古い順
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(article, index) in selected_articles"
        :key="index"
        class="article"
        v-if="selected_articles"
      >
        <ArticleIndexCard :article="article" />
      </div>
      <div id="no-article" v-else>
        <span>記事がありません</span>
      </div>
      <div id="loading" v-if="loading">
        <img src="/assets/images/loading.gif" />
      </div>
    </div>
  </section>
</template>

<script>
import { db } from "@/plugins/firebase.js";
import ArticleIndexCard from "@/components/admin/global/article/ArticleIndexCard.vue";
import dayjs from "dayjs";
export default {
  components: {
    ArticleIndexCard,
  },
  data() {
    return {
      articles: [],
      selected_articles: [],
      active_filter: 0,
      sort_property: null,
      is_descending: true, // trueなら降順 falseなら昇順
      loading: false,
    };
  },
  async created() {
    await this.fetch();
  },
  watch: {
    active_filter(val) {
      if (val === 0) {
        // すべて
        this.selected_articles = this.articles;
        this.sort_property = null;
        this.is_descending = true;
        this.selected_articles.sort((a, b) => {
          return dayjs(b.updated_date).unix() - dayjs(a.updated_date).unix();
        });
      } else if (val === 1) {
        //公開中
        this.selected_articles = this.articles.filter(
          (article) => article.status === 1
        );
        this.sort_property = null;
        this.is_descending = true;
        this.selected_articles.sort((a, b) => {
          return dayjs(b.updated_date).unix() - dayjs(a.updated_date).unix();
        });
      } else if (val === 2) {
        // 下書き
        this.selected_articles = this.articles.filter(
          (article) => article.status === 2
        );
        this.sort_property = null;
        this.is_descending = true;
        this.selected_articles.sort((a, b) => {
          return dayjs(b.updated_date).unix() - dayjs(a.updated_date).unix();
        });
      } else {
        this.selected_articles = this.articles;
        this.sort_property = null;
        this.is_descending = true;
        this.selected_articles.sort((a, b) => {
          return dayjs(b.updated_date).unix() - dayjs(a.updated_date).unix();
        });
      }
    },
    is_descending(val) {
      if (val) {
        this.selected_articles.sort((a, b) => {
          return dayjs(b.updated_date).unix() - dayjs(a.updated_date).unix();
        });
      } else {
        this.selected_articles.sort((a, b) => {
          return dayjs(a.updated_date).unix() - dayjs(b.updated_date).unix();
        });
      }
    },
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;
        await db
          .collection("gallary")
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              this.articles.push({
                ...doc.data(),
              });
              this.selected_articles.push({
                ...doc.data(),
              });
            });
            this.selected_articles = this.articles.sort((a, b) => {
              return (
                dayjs(b.updated_date).unix() - dayjs(a.updated_date).unix()
              );
            });
          });
      } catch (error) {
        console.log(error);
        alert("記事取得に失敗しました");
      } finally {
        this.loading = false;
      }
    },
    setIsDescending(poperty) {
      this.is_descending = poperty;
      this.sort_property = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#article-index {
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  background-color: #f7f7f7;
  @include flex-column(center, flex-start);
  #title {
    margin-bottom: 70px;
  }
  #article-num {
    width: 100%;
    border: 2px solid #d7d7d7;
    padding: 15px 0;
    margin: 30px 0;
    font-size: 0.8rem;
    font-weight: bold;
    color: black;
    border-radius: 30px;
  }
  #article-container {
    width: 50%;
    @include flex-column(center, flex-start);
    background-color: white;
    padding: 60px 80px;
    #filter {
      width: 70%;
      margin: 30px 0 60px 0;
      @include flex-row(center, center);
      .filter-name {
        min-width: 270px;
        padding: 10px 30px;
        color: #d7d7d7;
        border-bottom: 2px solid #d7d7d7;
        cursor: pointer;
        box-sizing: border-box;
        &.active {
          color: #2000aa;
          border-bottom: 2px solid #2000aa;
        }
      }
    }
    #sort-property-container {
      width: 100%;
      @include flex-row(flex-end, center);
      position: relative;
      border-bottom: 2px solid #f3f1f3;
      padding-right: 20px;
      box-sizing: border-box;
      #sort-property {
        color: black;
        font-weight: thin;
        cursor: pointer;
        font-size: 0.8rem;
      }
      #sort-property-icon {
        padding: 7px;
        &.up {
          background: url("https://api.iconify.design/eva:arrow-ios-upward-fill.svg?color=black")
            no-repeat center center / contain;
        }
        &.down {
          background: url("https://api.iconify.design/eva:arrow-ios-downward-fill.svg?color=black")
            no-repeat center center / contain;
        }
      }
      #sort-property-pulldown-container {
        position: absolute;
        top: 50px;
        right: -40px;
        #sort-propert-pulldown-inner {
          position: relative;
          z-index: 4;
          background-color: white;
          text-align: center;
          border: 3px solid rgba(0, 0, 0, 0.1);
          color: white;
          font-size: 0.9rem;
          font-weight: bold;
          border-radius: 5px;
          @include flex-column(center, flex-start);
          &:after,
          &:before {
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            pointer-events: none;
            position: absolute;
            bottom: 100%;
            left: 50%;
            z-index: 4;
          }
          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-top-width: 8px;
            border-bottom-width: 8px;
            border-left-width: 7px;
            border-right-width: 7px;
            margin-left: -7px;
            border-bottom-color: #ffffff;
            z-index: 5;
          }
          &:before {
            border-color: rgba(237, 237, 237, 0);
            border-top-width: 11px;
            border-bottom-width: 11px;
            border-left-width: 10px;
            border-right-width: 10px;
            margin-left: -10px;
            margin-bottom: 3px;
            border-bottom-color: rgba(0, 0, 0, 0.1);
            z-index: 5;
          }

          #sort-propert-pulldown {
            width: 100%;
            @include flex-column(center, flex-start);
            .sort {
              width: 100px;
              display: inline-block;
              color: #2000aa;
              cursor: pointer;
              padding: 10px 20px;
              position: relative;
              font-size: 0.8rem;
              &:hover {
                background-color: rgba(0, 0, 0, 0.1);
              }
              span {
                position: absolute;
                left: 15px;
              }
            }
          }
        }
      }
    }

    .article {
      width: 100%;
      border-bottom: 2px solid #f3f1f3;
      border-left: none;
      border-right: none;
      padding: 30px;
      box-sizing: border-box;
      &:first-child {
        border-top: 2px solid #f3f1f3;
      }
    }
    #no-article {
      width: 100%;
      @include flex-row(center, center);
      margin-top: 50px;
      height: 200px;
    }
    #loading {
      width: 100%;
      @include flex-row(center, center);
      margin-top: 50px;
      height: 200px;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
