<template>
  <section id="view" class="news-show">
    <div id="view-thumbnail-container" v-if="news">
      <img :src="news.thumbnail_url" alt="thumbnail" />
    </div>
    <div id="view-article-body" v-if="news">
      <div id="view-tag-container">
        <span class="tag">{{ news.tag }}</span>
      </div>
      <h1 id="view-title">
        {{ news.title }}
      </h1>
      <div id="posted-date-container">
        <span id="posted-date">{{ convertDate }}</span>
      </div>
      <div id="article-body" v-html="compiledMarkdown"></div>
    </div>
    <div id="other-news-container" v-if="news">
      <div id="news-titles">
        <div class="ja-title">その他の投稿</div>
        <h1 class="en-title">Other News</h1>
      </div>
      <div id="each-news-container">
        <div
          v-for="(news, i) in last_three_news"
          :key="i"
          class="each-news"
          v-show="!loading"
        >
          <EachNews :news="news" />
        </div>
      </div>
      <div id="news-loading" v-show="loading">
        <img src="/assets/images/loading.gif" />
      </div>
    </div>
  </section>
</template>
<script>
import marked from "marked";
import dayjs from "dayjs";
import { db } from "../plugins/firebase";
import "@/assets/stylesheets/global/articles/article_view.scss";
import { defineAsyncComponent } from "vue";
const LoadingComponent = {
  template: '<img src="/assets/images/loading.gif" />',
};
const ErrorComponent = {
  template: "<p>エラー</p>",
};
const EachNews = defineAsyncComponent({
  loader: () => import("@/components/EachNews.vue"),
  delay: 200,
  timeout: 3000,
  errorComponent: ErrorComponent,
  loadingComponent: LoadingComponent,
});
export default {
  data() {
    return {
      news: null,
      last_three_news: [],
      loading: false,
    };
  },
  components: {
    EachNews,
  },
  async mounted() {
    await this.fetchNews();
    marked.setOptions({ breaks: true });
    this.fetchThreeNews();
  },
  computed: {
    compiledMarkdown: function () {
      if (this.news.content) {
        return marked(this.news.content);
      } else {
        return null;
      }
    },
    convertDate() {
      return dayjs(this.news.posted_date).format("YYYY-MM-DD");
    },
  },
  methods: {
    async fetchNews() {
      try {
        this.loading = true;
        const res = await db
          .collection("news")
          .doc(this.$route.params.id)
          .get();

        const news = {
          id: res.data().id,
          thumbnail_url: res.data().thumbnail_url,
          posted_date: res.data().posted_date,
          tag: res.data().tag,
          title: res.data().title,
          content: res.data().content,
        };

        this.news = news;
      } catch (error) {
        console.dir(error);
        alert("Newsの取得に失敗しました");
      } finally {
        this.loading = false;
      }
    },
    async fetchThreeNews() {
      try {
        this.loading = true;
        this.last_three_news = [];
        const snapshot = await db
          .collection("news")
          .where("status", "==", 1) // 公開のNewsのみ
          .limit(3)
          .get();
        snapshot.forEach((doc) => {
          const news = {
            id: doc.data().id,
            image: doc.data().thumbnail_url,
            date: doc.data().newsed_date,
            tag: doc.data().tag,
            title: doc.data().title,
          };
          this.last_three_news.push(news);
        });
      } catch (error) {
        console.log(error);
        alert("Newsの取得に失敗しました");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .news-show {
    width: 50%;
    margin: 200px 0;
    min-height: 100vh;
    #view-article-body {
      width: 50% !important;
    }
    #other-news-container {
      width: 100%;
      margin-top: 100px;
      @include flex-column(center, center);
      #news-titles {
        width: 75%;
        @include flex-column(space-around, center);
      }
      #each-news-container {
        width: 75%;
        @include flex-row(center, center);
        padding: 0 20px;
        box-sizing: border-box;
        .each-news {
          width: 100%;
          margin: 20px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .news-show {
    width: 100%;
    margin: 100px 0;
    min-height: 100vh;
    #view-article-body {
      width: 50% !important;
    }
    #other-news-container {
      width: 100%;
      margin-top: 100px;
      @include flex-column(center, flex-start);
      #news-titles {
        @include flex-column(space-around, center);
      }
      #each-news-container {
        width: 100%;
        @include flex-column(center, flex-start);
        .each-news {
          margin: 30px 0;
          @include flex-row(space-around, center);
        }
      }
    }
  }
}
@media screen and (max-width: 599px) {
  .news-show {
    margin: 100px 0;
    min-height: 100vh;
    #view-article-body {
      width: 85% !important;
    }
    #other-news-container {
      width: 100%;
      margin-top: 100px;
      @include flex-column(center, flex-start);
      #news-titles {
        @include flex-column(space-around, center);
      }
      #each-news-container {
        width: 100%;
        @include flex-column(center, flex-start);
        .each-news {
          margin: 20px 0;
        }
      }
    }
  }
}
</style>
