<template>
  <section id="admin-article-post" @click.self="change_date = false">
    <div id="article" v-if="article" @click.self="set_tags = false">
      <div
        id="article-editor-container"
        v-if="!preview"
        @click.self="set_tags = false"
      >
        <div id="thumbnail-url-container">
          <div id="thumbnail-url">
            <label for="thumbnail-image-field" v-if="!article.thumbnail_url">
              <div id="thumbnail-image-icon"></div>
              <span id="article-image-description">
                アップロードする画像を選択
              </span>
              <input
                type="file"
                @change="imageCompressor($event, 'thumbnail')"
                id="thumbnail-image-field"
              />
            </label>
            <label for="thumbnail-image-field" v-else>
              <img :src="article.thumbnail_url" alt="サムネ" />
              <!-- サムネ画像が投稿された時はそれを表示 -->
              <input
                type="file"
                @change="imageCompressor($event, 'thumbnail')"
                id="thumbnail-image-field"
              />
            </label>
          </div>
        </div>
        <div id="tag-and-post-type-container">
          <div id="article-tag-container" @click.self="set_tags = false">
            <input
              type="text"
              list="tags"
              placeholder="テーマを入力"
              autocomplete="off"
              id="tag-form"
              v-model="article.tag"
              @click="set_tags = !set_tags"
            />

            <transition name="fade">
              <div id="already-tags-container" v-show="set_tags">
                <div id="already-tags">
                  <div id="already-tags-title">
                    <span id="tag-history"> タグ履歴 </span>
                    <span id="all-tag-delete" @click="allTagDelete">
                      すべて削除
                    </span>
                  </div>
                  <div id="tag-list">
                    <div
                      v-for="(tag, i) in tags"
                      :value="tag"
                      :key="i"
                      class="tag"
                    >
                      <span class="tag-content" @click="article.tag = tag">{{
                        tag
                      }}</span>
                      <span class="tag-delete" @click="tagDelete(i)">×</span>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div id="post-type">
            <label
              ><input
                type="radio"
                v-model="article.type"
                value="gallary"
              />Gallary</label
            >
            <label
              ><input
                type="radio"
                v-model="article.type"
                value="news"
              />News</label
            >
          </div>
        </div>
        <div id="article-title-container">
          <input
            type="text"
            placeholder="タイトルを入力"
            v-model="article.title"
          />
        </div>
        <div id="article-post-date-container">
          <span id="date" @click="openCalender()">{{ convertDate() }}</span>
        </div>

        <div
          id="article-calender-container"
          v-show="change_date"
          @click.self="closeCalender()"
        >
          <div id="datepicker-container">
            <v-date-picker
              :mode="article.date.mode"
              :formats="article.date.formats"
              v-model="article.date.selectedDate"
            ></v-date-picker>
          </div>
        </div>
        <div id="menus"></div>
        <div id="article-editor">
          <div id="article-image-uploader-container">
            <label for="article-image-field">
              <div id="article-image-icon"></div>
              <input
                type="file"
                @change="imageCompressor($event, 'article')"
                id="article-image-field"
              />
            </label>
          </div>

          <textarea
            v-model="edited_content"
            placeholder="本文を入力"
            :rows="rows"
          ></textarea>
        </div>
      </div>
      <div id="article-preview" v-else-if="preview">
        <Preview v-model:article="article" />
      </div>
    </div>
    <div id="buttons">
      <div id="post-button-container">
        <button id="post-button" @click="updateArticle()">
          {{ articleStatusDisplay }}
        </button>
        <button
          id="save-type-container-opener"
          @click="select_save_type = !select_save_type"
          :class="{ up: select_save_type, down: !select_save_type }"
        >
          &ensp;
        </button>
        <transition name="fade">
          <div id="save-type-container" v-show="select_save_type">
            <span class="save-type" @click="setStatusDisplay(2)"
              ><span v-if="article.status === 2">✔</span>下書き保存</span
            >
            <span class="save-type" @click="setStatusDisplay(1)"
              ><span v-if="article.status === 1">✔</span>投稿</span
            >
          </div>
        </transition>
      </div>
      <div id="preview-button-container">
        <button id="preview-button" @click="preview = !preview">
          {{ preview ? "修正する" : "プレビュー" }}
        </button>
      </div>
    </div>
    <div id="loading" v-if="loading">
      <img src="/assets/images/loading.gif" />
    </div>
  </section>
</template>

<script>
import marked from "marked";
import { storage, db, functions } from "../../../plugins/firebase";
import Compressor from "compressorjs";
import dayjs from "dayjs";
import Preview from "../../../components/admin/global/ArticleView.vue";
export default {
  components: {
    Preview,
  },
  data() {
    return {
      article: {
        id: String(dayjs().unix()), //現在時刻をIDにする
        title: null,
        thumbnail_url: null,
        status: 2, // 1 なら公開 2なら下書き
        content: null,
        type: null,
        tag: null,
        date: {
          mode: "single",
          formats: {
            input: ["YYYY-MM-DD"],
          },
          selectedDate: dayjs().format("YYYY-MM-DD"),
        },
      },
      set_tags: false,
      select_save_type: false,
      preview: false,
      change_date: false,
      edited_content: "",
      status_array: [1, 2],
      saved_flag: false,
      image_urls: [],
      tags: [],
      loading: false,
      before_edited_type: null,
    };
  },
  async mounted() {
    try {
      this.loading = true;
      await this.fetch();
      await this.getTags();
      window.addEventListener("beforeunload", this.confirmSave);
      const image_urls = JSON.parse(localStorage.getItem("image_urls")); //前回要らない画像をstorageに保存したままページを閉じていた際のお掃除
      if (image_urls) {
        for (let i = 0; i < image_urls.length; i++) {
          const storageRef = storage.ref();
          const delete_target = storageRef.child(image_urls[i]);
          await delete_target.delete();
        }
        localStorage.removeItem("image_urls");
      }
      marked.setOptions({ breaks: true });
      this.loading = false;
    } catch (error) {
      console.log(error);
      alert("エラーが発生しました");
    }
  },

  unmounted() {
    window.removeEventListener("beforeunload", this.confirmSave);
  },

  computed: {
    rows() {
      const num = this.edited_content.split("\n").length;
      return num > 4 ? num : 4;
    },
    compiledMarkdown: function () {
      if (this.edited_content) {
        return marked(this.edited_content);
      } else {
        return null;
      }
    },
    convertDisplayStatus() {
      return function (value) {
        if (value === 1) {
          return "公開";
        } else {
          return "下書き";
        }
      };
    },
    convertDate() {
      return function (value) {
        return dayjs(this.article.date.selectedDate).format("YYYY-MM-DD");
      };
    },
    articleStatusDisplay() {
      if (this.article.status === 1) {
        return "投稿";
      } else if (this.article.status === 2) {
        return "下書き保存";
      } else {
        return "バグが起きます";
      }
    },
  },
  watch: {
    "article.date.selectedDate": function (val) {
      this.change_date = false;
    },
    edited_content(val) {
      this.article.content = val;
    },
  },
  methods: {
    async fetch() {
      try {
        const res = await db
          .collection(this.$route.query.type)
          .doc(this.$route.params.id)
          .get();
        this.article = {
          id: res.data().id, //現在時刻をIDにする
          title: res.data().title,
          thumbnail_url: res.data().thumbnail_url,
          status: res.data().status, // 1 なら公開 2なら下書き
          content: res.data().content,
          type: res.data().type,
          tag: res.data().tag,
          date: {
            mode: "single",
            formats: {
              input: ["YYYY-MM-DD"],
            },
            selectedDate: dayjs(res.data().posted_date).format("YYYY-MM-DD"),
          },
        };
        this.before_edited_type = this.article.type;
        this.edited_content = res.data().content;
      } catch (error) {
        console.log(error);
        alert("エラーが発生しました");
      }
    },
    async getTags() {
      try {
        const res = await db.collection("tags").doc("article_tag").get();
        this.tags = res.data().tags;
      } catch (error) {
        console.log(error);
        alert("エラーが発生しました");
      }
    },
    openCalender() {
      document.getElementById("article-calender-container").style.height =
        document.getElementById("admin").scrollHeight + "px";
      this.change_date = true;
    },
    closeCalender() {
      this.change_date = false;
      document.getElementById("article-calender-container").style.height = null;
    },
    setStatusDisplay(val) {
      this.article.status = val;
      this.select_save_type = false;
    },
    confirmSave(event) {
      event.returnValue = "編集中のものは保存されませんが、よろしいですか？";
    },
    async imageCompressor(event, type) {
      try {
        this.loading = true;
        const files = event.target.files || event.dataTransfer.files;
        const file = files[0];
        self = this;
        new Compressor(file, {
          quality: 0.7,
          success(result) {
            const formData = new FormData();
            formData.append("file", result, result.name);
            self.firebaseImageUploader(result, type);
          },
        });
      } catch (error) {
        console.log(error);
        alert("画像を圧縮できませんでした");
        this.loading = false;
      }
    },
    async firebaseImageUploader(file, type) {
      try {
        const storageRef = storage.ref();
        const snapshot = await storageRef
          .child(
            `${process.env.NODE_ENV}/images/article/${this.article.id}/${file.name}`
          )
          .put(file);
        this.saved_flag = false;
        this.image_urls.push(
          `${process.env.NODE_ENV}/images/article/${this.article.id}/${file.name}`
        );
        localStorage.setItem("image_urls", JSON.stringify(this.image_urls));
        const downloadURL = await snapshot.ref.getDownloadURL();
        const shortenLinkWithBitly = functions.httpsCallable(
          "shortenLinkWithBitly"
        );
        const res = await shortenLinkWithBitly({ image_url: downloadURL }); // 画像URLが地味に長いので、bit.ly APIを叩いて短縮水産！！
        const shorten_url = res.data;

        if (type === "thumbnail") {
          this.article.thumbnail_url = shorten_url;
          document.getElementById("thumbnail-image-field").value = null;
        } else {
          const url = `<img src="${shorten_url}" alt=${file.name} />`;
          this.edited_content += url;
          document.getElementById("article-image-field").value = null;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("画像が投稿できませんでした");
        this.loading = false;
      }
    },
    async tagDelete(i) {
      try {
        this.loading = true;
        this.tags.splice(i, 1);
        await db.collection("tags").doc("article_tag").set({
          tags: this.tags,
        });
        this.getTags();
      } catch (error) {
        console.log(error);
        alert("エラーが発生しました");
      } finally {
        this.loading = false;
      }
    },
    async allTagDelete() {
      try {
        this.loading = true;
        const tags = [];
        await db.collection("tags").doc("article_tag").set({
          tags: tags,
        });
        this.getTags();
      } catch (error) {
        console.log(error);
        alert("エラーが発生しました");
      } finally {
        this.loading = false;
      }
    },
    async updateArticle() {
      try {
        if (this.article.status === 1) {
          const check = confirm(
            "公開ステータスが「公開」なので、保存すると公開されますがよろしいですか？？"
          );
          if (!check) {
            return;
          }
        }

        if (this.article.title === "" || this.article.title === null) {
          alert("タイトルが入力されていません");
          return;
        }
        if (
          this.article.thumbnail_url === "" ||
          this.article.thumbnail_url === null
        ) {
          alert("サムネ画像が設定されていません");
          return;
        }
        if (this.article.tag === "" || this.article.tag === null) {
          alert("タグが設定されていません");
          return;
        }
        this.loading = true;
        const type = this.article.type;
        if (this.before_edited_type !== type) {
          // 投稿の種類が変わったら、変える前の投稿を消す
          await await db
            .collection(this.before_edited_type)
            .doc(this.article.id)
            .delete();
        }
        await db
          .collection(type)
          .doc(this.article.id)
          .set({
            id: this.article.id,
            title: this.article.title,
            thumbnail_url: this.article.thumbnail_url,
            updated_date: String(dayjs().format("YYYY-MM-DD")),
            posted_date: String(this.convertDate()),
            type: this.article.type,
            status: this.article.status,
            content: this.edited_content,
            tag: this.article.tag,
          });

        let tags = this.tags; // 既存のタグたち
        tags.push(this.article.tag); //新しいタグ追加
        tags = new Set(tags); //重複タグ削除
        tags = Array.from(tags); //配列に戻す
        await db.collection("tags").doc("article_tag").set({
          tags: tags,
        });
        alert("更新しました");
        this.image_urls = [];
        localStorage.removeItem("image_urls");
        this.saved_flag = true;
        this.loading = false;
        window.removeEventListener("beforeunload", this.confirmSave);
        this.$router.push(`/admin/${type}/index`);
      } catch (error) {
        console.log(error);
        alert("エラーが発生しました");
        this.loading = false;
      }
    },
  },
  async beforeRouteLeave(to, from, next) {
    try {
      if (!this.saved_flag) {
        const answer = confirm("編集中の内容は全て消えますがよろしいですか？");
        if (answer) {
          if (!this.saved_flag) {
            // 保存しなかった画像をfirebase storageから削除
            for (let i = 0; i < this.image_urls.length; i++) {
              const storageRef = storage.ref();
              const delete_target = storageRef.child(this.image_urls[i]);
              await delete_target.delete();
            }
            localStorage.removeItem("image_urls");
            this.saved_flag = true;
            next();
          } else {
            next();
          }
        } else {
          next(false);
        }
      } else [next()];
    } catch (error) {
      console.log(error);
      alert("エラーが発生しました");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/admin/articles/edit.scss";
</style>
