import { createStore } from "vuex";
import Cookies from "js-cookie";

export default createStore({
  state: {
    token: Cookies.get("token"),
  },
  mutations: {},
  actions: {},
  getters: {
    getToken(state) {
      return state.token;
    },
  },
});
