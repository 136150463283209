<template>
  <div id="side-bar">
    <div class="menu" v-for="(menu, index) in menus" :key="index">
      <div class="menu-title">{{ menu.title }}</div>
      <div
        v-for="(subMenu, i) in menu.subMenus"
        :key="i"
        @click="PageTransition(subMenu.path)"
        class="sub-menu"
      >
        <span>{{ subMenu.title }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    menu_opener: Boolean,
  },
  data() {
    return {
      menus: [
        {
          title: "投稿",
          subMenus: [
            {
              path: "/admin/post/new",
              title: "新規投稿",
            },
            {
              path: "/admin/gallary/index",
              title: "Gallary",
            },
            {
              path: "/admin/news/index",
              title: "News",
            },
          ],
        },
        {
          title: "設定",
          subMenus: [
            {
              path: "/admin/settings",
              title: "設定",
            },
          ],
        },
      ],
    };
  },
  methods: {
    PageTransition(path) {
      this.$router.push(path);
      this.$emit("update:menu_opener", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#side-bar {
  width: 300px;
  height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;
  overflow: auto;
  background-color: white;
  @include flex-column(center, flex-start);
  position: fixed;
  top: 0;
  left: 0;
  .menu {
    width: 100%;
    .menu-title {
      width: 100%;
      @include flex-row(flex-start, center);
      padding: 10px 0 10px 30px;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.3);
      @media screen and (max-width: 767px) {
        font-size: 1rem;
      }
      @media screen and (min-width: 768px) {
        font-size: 0.8rem;
      }
    }
    .sub-menu {
      @include flex-row(flex-start, center);
      text-decoration: none;
      width: 100%;
      padding: 10px 0 10px 50px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      span {
        @media screen and (max-width: 767px) {
          font-size: 1.2rem;
        }
        @media screen and (min-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }
  box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
    12px 0 15px -4px rgba(0, 0, 0, 0.2), -12px 0 15px -4px rgba(0, 0, 0, 0.2);
}
</style>
