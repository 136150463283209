<template>
  <section id="admin">
    <header id="admin-header">
      <div id="admin-header-left" @click="menu_opener = !menu_opener">
        <div id="menu-button-container">
          <span class="menu">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
      </div>
    </header>
    <transition name="left">
      <div id="sidebar-container" v-show="menu_opener">
        <SideBar v-model:menu_opener="menu_opener" />
      </div>
    </transition>
    <!-- adminのページはいかのrouter-viewで描写 -->
    <router-view />
  </section>
</template>
<script>
import SideBar from "@/components/admin/global/SideBar.vue";
export default {
  components: {
    SideBar,
  },
  data() {
    return {
      menu_opener: false,
    };
  },
};
</script>
<style lang="scss" scoped>
#admin-header {
  width: 100px;
  height: 50px;
  @include flex-row(flex-start, center);
  box-shadow: border-box;
  position: absolute;
  top: 0;
  z-index: 5;
  padding-left: 0px;
  #admin-header-left {
    height: 50px;
    @include flex-row(flex-start, center);

    #menu-button-container {
      @include flex-column(flex-start, center);
      height: 50px;
      width: 100px;
      cursor: pointer;
      .menu {
        position: relative;
        width: 25px;
        height: 20px;
        display: inline-block;
        transition: all 0.4s;
        box-sizing: border-box;
        top: 0;
        left: -20px;
        right: 0;
        margin: auto;
        span {
          display: inline-block;
          transition: all 0.4s;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: black;
          cursor: pointer;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 9px;
          }
          &:nth-of-type(3) {
            bottom: 0;
          }
        }
        &:focus {
          outline: 0;
        }
      }
    }
  }
}
#sidebar-container {
  @include flex-row(flex-start, center);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
</style>
