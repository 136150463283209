import firebase from "firebase";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";
import Cookies from "js-cookie";
const firebaseConfig = {
  apiKey: "AIzaSyC2dTtAVZMhcihKzkRc-NtTakriYarv68g",
  authDomain: "masatokamishohara-acb08.firebaseapp.com",
  projectId: "masatokamishohara-acb08",
  storageBucket: "masatokamishohara-acb08.appspot.com",
  messagingSenderId: "699365490356",
  appId: "1:699365490356:web:f169c8c9d25f9a82a221e1",
  measurementId: "G-ZMMLFW5QSV",
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export default {
  async signIn(email, password) {
    try {
      const user = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      Cookies.set("token", user.uid, { expires: 7 }); //1週間Cokkieを保持
      return {
        status: true,
        message: user.uid,
      };
    } catch (error) {
      return {
        status: false,
        message: `${error.code}\n\n${error.message}`,
      };
    }
  },
  async signOut() {
    try {
      await firebase.auth().signOut();
      Cookies.remove("token");
      return {
        status: true,
        message: "Success",
      };
    } catch (error) {
      return {
        status: false,
        message: `${error.code}\n\n${error.message}`,
      };
    }
  },
};
export const db = firebase.firestore(); // firestore使う
export const storage = firebase.storage(); // strage使う
export const functions = firebase.functions(); // strage使う
