import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index.js";
import Top from "@/views/Top.vue";
import Profile from "@/views/Profile";
import Calendar from "@/views/Calendar.vue";
import Contact from "@/views/Contact.vue";
import Signin from "@/views/admin/auth/Signin.vue";
import Gallary from "@/views/Gallary.vue";
import Traning from "@/views/Traning.vue";
import NewsIndex from "@/views/NewsIndex.vue";
import NewsShow from "@/views/NewsShow.vue";
import Admin from "@/views/admin/Admin.vue";
import PostNew from "@/views/admin/posts/New.vue";
import AdminGallaryIndex from "@/views/admin/posts/GallaryIndex.vue";
import AdminNewsIndex from "@/views/admin/posts/NewsIndex.vue";
import PostEdit from "@/views/admin/posts/Edit.vue";
import AdminSetting from "@/views/admin/Settings.vue";
const routes = [
  {
    path: "/",
    name: "Top",
    component: Top,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/gallary",
    name: "Gallary",
    component: Gallary,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/traning",
    name: " Traning ",
    component: Traning,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/news",
    name: "NewsIndex",
    component: NewsIndex,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/news/:id",
    name: "NewsShow",
    component: NewsShow,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/admin",
    component: Admin,
    children: [
      {
        path: "gallary/index",
        component: AdminGallaryIndex,
      },
      {
        path: "news/index",
        component: AdminNewsIndex,
      },
      {
        path: "post/new",
        component: PostNew,
      },
      {
        path: "post/edit/:id",
        component: PostEdit,
      },
      {
        path: "settings",
        component: AdminSetting,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((page) => page.meta.isPublic) || store.getters.getToken) {
    next();
  } else {
    next("/signin");
  }
});
export default router;
