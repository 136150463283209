<template>
  <section>
    <Header v-if="!isAdmin" />
    <main>
      <router-view />
    </main>
    <Footer v-if="!isAdmin" />
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    isAdmin() {
      return this.$route.path.match(/admin|signin/);
    },
  },
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  color: $main2-color;
}
main {
  margin: 0 90px 0 0;
  padding: 0;
}
@media screen and (max-width: 1024px) {
  main {
    margin: 0;
  }
}
</style>
