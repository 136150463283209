<template>
  <section id="contact">
    <main>
      <div id="top">
        <div class="ja-title">お問い合わせ</div>
        <h1 class="en-title">Contact</h1>
      </div>
      <div id="desc">
        上正原真人へのお問い合わせ、<br
          class="br-mob"
        />応援メッセージはこちらから送りいただけます。
      </div>
      <div id="top_border"></div>
      <div id="item-container">
        <div class="item">
          <div class="label">お名前</div>
          <input
            type="text"
            class="input_field"
            id="name"
            placeholder="名前 太郎"
            v-model="inputForm.name"
          />
        </div>
        <div class="item">
          <div class="label">メールアドレス</div>
          <input
            type="email"
            class="input_field"
            id="email"
            placeholder="namae@namae.com"
            v-model="inputForm.email"
          />
        </div>
        <div class="item">
          <div class="label">件名</div>
          <input
            type="text"
            class="input_field"
            id="title"
            placeholder="Title"
            v-model="inputForm.title"
          />
        </div>
        <div class="item">
          <div class="label">内容</div>
          <textarea
            class="text_field"
            id="text"
            cols="50"
            rows="10"
            placeholder="Text"
            v-model="inputForm.text"
          ></textarea>
        </div>
      </div>
      <div id="button_field">
        <button id="button" v-on:click="clickButton">
          <i v-if="loadingMail" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>送信</span>
        </button>
        <div id="error-message" v-if="inputInvalid">{{ errorMessage }}</div>
        <div id="submit-message" v-if="isSubmitted">送信しました。</div>
        <div></div>
      </div>
      <div id="unlim_button_field">
        <div id="unlim_text">＼ 上正原をもっと応援しよう！ ／</div>
        <button id="unlim_button">Unlim</button>
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      inputForm: {
        name: "",
        email: "",
        title: "",
        text: "",
      },
      errorMessage: `入力内容が不十分です。`,
      inputInvalid: false,
      isSubmitted: false,
      loadingMail: false,
    };
  },
  methods: {
    async clickButton() {
      this.isSubmitted = false;
      this.loadingMail = true;
      this.formValidation();
      await this.sendMail();
      this.loadingMail = false;
    },
    sendMail() {
      if (this.inputInvalid) {
        return;
      }
      this.axios
        .post(
          "https://us-central1-masatokamishohara-acb08.cloudfunctions.net/sendMail",
          this.inputForm
        )
        .then((res) => {
          if (res.status === 200) {
            this.sendSuccess();
          } else {
            this.sendFailure();
          }
        })
        .catch((error) => {
          this.sendFailure();
        });
    },
    sendSuccess() {
      alert("メールの送信に成功しました。");
      this.isSubmitted = true;
      this.inputForm.name = "";
      this.inputForm.email = "";
      this.inputForm.title = "";
      this.inputForm.text = "";
    },
    sendFailure() {
      alert(
        "メールの送信に失敗しました。\n繰り返し同様の操作をしても改善されない場合、kamisho@example.comにまで直接お問い合わせください。"
      );
      this.errorMessage = "送信エラー。再度お試しください。";
      this.inputInvalid = true;
    },
    formValidation() {
      this.errorMessage = "";
      this.inputInvalid = false;
      const form = this.inputForm;
      if (!form.name) {
        this.inputInvalid = true;
        this.errorMessage += "名前 ";
      }
      if (!form.email) {
        this.inputInvalid = true;
        this.errorMessage += "メールアドレス ";
      }
      if (!form.title) {
        this.inputInvalid = true;
        this.errorMessage += "件名 ";
      }
      if (!form.text) {
        this.inputInvalid = true;
        this.errorMessage += "本文 ";
      }
      this.errorMessage += "が未入力です";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/stylesheets/Contact/contact.scss";
</style>
