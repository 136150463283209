<template>
  <div id="view">
    <div id="view-thumbnail-container">
      <img
        :src="article.thumbnail_url"
        v-if="article.thumbnail_url"
        alt="thumbnail"
      />
      <div id="no-image" v-else>
        <span>サムネが設定されていません</span>
      </div>
    </div>
    <div id="view-article-body">
      <div id="view-tag-container">
        <span class="tag">{{
          article.tag ? article.tag : "タグが未設定です"
        }}</span>
      </div>
      <h1 id="view-title">
        {{ article.title ? article.title : "タイトルが未入力です" }}
      </h1>
      <div id="posted-date-container">
        <span id="posted-date">{{ convertDate() }}</span>
      </div>
      <div id="article-body" v-html="compiledMarkdown"></div>
    </div>
  </div>
</template>
<script>
import marked from "marked";
import dayjs from "dayjs";
import "@/assets/stylesheets/global/articles/article_view.scss";
export default {
  props: {
    article: Object,
  },
  mounted() {
    marked.setOptions({ breaks: true });
  },
  computed: {
    compiledMarkdown: function () {
      if (this.article.content) {
        return marked(this.article.content);
      } else {
        return null;
      }
    },
    convertDate() {
      return function (value) {
        return dayjs(this.article.date.selectedDate).format("YYYY-MM-DD");
      };
    },
  },
};
</script>
<style lang="scss" scoped>
#view {
  width: 100%;
}
</style>
