import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import axiosFunctions from "@/mixins/axios.js";
import VCalendar from "v-calendar";
const app = createApp(App);
app.use(VueAxios, axios);
app.use(store);
app.use(router);
app.use(VCalendar);
app.mixin(axiosFunctions);
app.mount("#app");
