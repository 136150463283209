<template>
  <section id="gallary">
    <main v-show="!loading">
      <div id="top">
        <div class="ja-title" id="ja-title">ギャラリー</div>
        <h1 class="en-title" id="en-title">Gallary</h1>
      </div>
      <div id="container">
        <div id="gallary-articles">
          <div
            class="article"
            v-for="(article, index) in articles"
            :key="index"
          >
            <GallaryDetail :article="article" :index="index" />
          </div>
        </div>
      </div>
    </main>
    <main v-show="loading" id="gallary-loading">
      <img src="/assets/images/loading.gif" />
    </main>
  </section>
</template>
<script>
import { db } from "@/plugins/firebase.js";
import dayjs from "dayjs";
import { defineAsyncComponent } from "vue";
const LoadingComponent = {
  template: '<img src="/assets/images/loading.gif" />',
};

const ErrorComponent = {
  template: "<p>エラー</p>",
};
const GallaryDetail = defineAsyncComponent({
  loader: () => import("@/components/GallaryDetail.vue"),
  delay: 200,
  timeout: 3000,
  errorComponent: ErrorComponent,
  loadingComponent: LoadingComponent,
});

export default {
  components: {
    GallaryDetail,
  },
  data() {
    return {
      articles: [],
      loading: true,
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    imageHeight() {
      return function (index) {
        const el = document.getElementById("image" + index);
        return el ? el.clientHeight : 0;
      };
    },
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;
        await db
          .collection("gallary")
          .where("status", "==", 1)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              this.articles.push({
                ...doc.data(),
              });
            });
            this.articles = this.articles.sort((a, b) => {
              return (
                dayjs(b.posted_date).unix() - dayjs(a.posted_date).unix() //投稿日順にする
              );
            });
          });
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("記事取得に失敗しました");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/Gallary/gallary.scss";
</style>
