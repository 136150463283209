<template>
  <section id="traning">
    <main>
      <div id="traing-top">
        <div id="ja-title" class="ja-title">トレーニング</div>
        <h1 class="en-title">Traning</h1>
        <p>上正原正人のトレーニング情報を公開しています。</p>
      </div>
      <div id="spreadsheet-container">
        <iframe
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRPmrw8jaERJ8-Y-rIYcbXRaDWvtoIQidQXz7qCOunv8VEH0MXol_qHa8WOa4Tp8-Ib65xz36EtGwtQ/pubhtml?widget=true&amp;headers=false"
        ></iframe>
      </div>
    </main>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
#traning {
  width: 100%;
  height: 100vh;
  #traing-top {
    width: 100%;
    @include flex-column(center, center);
    margin: 100px 0 40px 0;
    #ja-title {
      letter-spacing: 0.1vw;
      font-weight: bold;
      font-size: 15px;
    }
    h1 {
      letter-spacing: 0.1vw;
      font-size: 50px;
      font-weight: 900;
      margin: 0.5vh 0 0 0;
      @media screen and (max-width: 599px) {
        font-size: 40px;
      }
    }
    p {
      font-weight: thin;
      margin-top: 30px;
      @media screen and (max-width: 599px) {
        font-size: 14px;
      }
    }
  }
  #spreadsheet-container {
    width: 100%;
    margin-top: 20px;
    iframe {
      width: 70%;
      height: 70vh;
      @media screen and (max-width: 1024px) {
        width: 80%;
      }
      @media screen and (max-width: 599px) {
        width: 99%;
      }
    }
  }
}
</style>
