<template>
  <div class="article-card" v-if="article">
    <div class="article-status-container">
      <span class="article-status" :class="{ is_public: article.status === 1 }">
        {{ statusDisplay(article.status) }}
      </span>
    </div>
    <div class="article-info">
      <h3 class="article-title">
        {{ article.title }}
      </h3>
      <span class="article-last-updated-info">
        最終更新日&ensp;&ensp;{{ article.updated_date }}
      </span>
    </div>
    <div class="article-edit-link-container">
      <router-link
        :to="'/admin/post/edit/' + article.id + '?type=' + article.type"
      >
        編集
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    article: null,
  },
  computed: {
    statusDisplay() {
      return function (status) {
        return status === 1 ? "公開" : "下書き";
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.article-card {
  @include flex-row(space-between, center);
  .article-status-container {
    width: 25%;
    @include flex-column(center, baseline);
    .article-status {
      width: 50px;
      padding: 10px 20px;
      border: 2px solid #d7d7d7;
      color: black;
      border-radius: 30px;
      font-size: 0.6rem;
      &.is_public {
        background-color: #2000aa;
        border: 2px solid #2000aa;
        color: white;
      }
    }
  }
  .article-info {
    width: 45%;
    @include flex-column(flex-start, center);
    .article-title {
      color: black;
      word-break: break-all;
      margin: 0;
      text-align: left;
    }
    .article-last-updated-info {
      display: inline-block;
      font-size: 0.7rem;
      color: #989898;
      margin-top: 20px;
    }
  }
  .article-edit-link-container {
    width: 30%;
    @include flex-row(flex-end, center);
    a {
      padding: 10px 20px;
      border: 2px solid #d7d7d7;
      cursor: pointer;
      text-decoration: none;
      color: black;
    }
  }
}
</style>
