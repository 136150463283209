<template>
  <footer>
    <div id="sponsor">
      <div id="sponsor_main">
        <div id="sponsor_rungry">
          <img src="/assets/images/rungry.png" alt="rungry" />
        </div>
        <div id="sponsor_salomon">
          <img src="/assets/images/salomon.png" alt="rungry" />
        </div>
      </div>
      <div id="sponsor_border"></div>
      <div id="sponsor_mob">
        <div id="sponsor_mob_icons">
          <a
            id="sponsor_mob_insta"
            class="fab fa-instagram fa-2x"
            href="https://www.instagram.com/kamishohara_masato/?hl=ja"
            target="_blank"
          ></a>
          <a
            class="fab fa-facebook-f fa-2x"
            href="https://www.facebook.com/masato.kamishohara"
            target="_blank"
          ></a>
        </div>
        <div id="sponsor_mob_border"></div>
      </div>
    </div>
    <div id="footer_copyright">
      <div id="footer_copyright_content">
        <span>© 2021 kamisho inc</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "src/assets/stylesheets/Footer/footer-pc.scss";
@import "src/assets/stylesheets/Footer/footer-tab.scss";
@import "src/assets/stylesheets/Footer/footer-mob.scss";
</style>
