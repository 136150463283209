<template>
  <header>
    <div id="header_pc">
      <div id="header_left">
        <div id="header_top">
          <router-link to="/">
            <div id="header_image"></div>
          </router-link>
        </div>
      </div>
      <div id="header_right">
        <div id="header_content" class="en-title">
          <nav>
            <ul>
              <li>
                <router-link to="/profile">Profile</router-link>
              </li>
              <li>
                <router-link to="/calendar">Calendar</router-link>
              </li>
              <li>
                <router-link to="/gallary">Gallary</router-link>
              </li>
              <li>
                <router-link to="/traning">Training</router-link>
              </li>
              <li>
                <router-link to="/news">News</router-link>
              </li>
              <li>
                <router-link to="/contact">Contact</router-link>
              </li>
              <li>
                <div class="header_external">
                  <div id="unlim_pos">
                    <i class="fas fa-external-link-alt external_size"></i>
                  </div>
                  <div>
                    <a
                      href="https://unlim.team/athletes/masato.kamishouhara"
                      target="_blank"
                      class="header_external_text"
                      >Unlim</a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="header_external">
                  <div id="ma_pos">
                    <i class="fas fa-external-link-alt external_size"></i>
                  </div>
                  <div>
                    <a
                      href="https://www.facebook.com/mountain.addicts.japan/"
                      target="_blank"
                      class="header_external_text"
                      >Mountain<br />Addicts</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </nav>
          <div id="header_icons">
            <a
              class="fab fa-instagram fa-2x"
              href="https://www.instagram.com/kamishohara_masato/?hl=ja"
              target="_blank"
            ></a>
            <a
              class="fab fa-facebook-f fa-2x"
              href="https://www.facebook.com/masato.kamishohara"
              target="_blank"
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div id="header_mob">
      <div id="header_mob_content">
        <router-link to="/" v-on:click="reverseSideOpen">
          <div id="header_mob_image"></div>
        </router-link>
        <i
          v-if="sideOpened"
          v-on:click="reverseSideOpen"
          class="fas fa-times fa-2x"
        ></i>
        <i v-else v-on:click="reverseSideOpen" class="fas fa-bars fa-2x"></i>
      </div>
    </div>
    <div v-if="sideOpened" id="header_mob_view">
      <div id="header_mob_view_content" class="en-title">
        <nav>
          <ul>
            <li>
              <router-link to="/profile" v-on:click="reverseSideOpen"
                >Profile</router-link
              >
            </li>
            <li>
              <router-link to="/calendar" v-on:click="reverseSideOpen"
                >Calendar</router-link
              >
            </li>
            <li>
              <router-link to="/gallary" v-on:click="reverseSideOpen"
                >Gallary</router-link
              >
            </li>
            <li>
              <router-link to="/traning" v-on:click="reverseSideOpen"
                >Training</router-link
              >
            </li>
            <li>
              <router-link to="/news" v-on:click="reverseSideOpen"
                >News</router-link
              >
            </li>
            <li>
              <router-link to="/contact" v-on:click="reverseSideOpen"
                >Contact</router-link
              >
            </li>
            <li>
              <div class="header_mob_external">
                <a
                  href="https://unlim.team/athletes/masato.kamishouhara"
                  target="_blank"
                  class="header_mob_external_text"
                  v-on:click="reverseSideOpen"
                  >Unlim</a
                >
                <div class="header_mob_external_icon">
                  <i class="fas fa-external-link-alt external_size"></i>
                </div>
              </div>
            </li>
            <li>
              <div class="header_mob_external">
                <a
                  href="https://www.facebook.com/mountain.addicts.japan/"
                  target="_blank"
                  class="header_mob_external_text"
                  v-on:click="reverseSideOpen"
                  >MountainAddicts</a
                >
                <div class="header_mob_external_icon">
                  <i class="fas fa-external-link-alt external_size"></i>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      sideOpened: false,
    };
  },
  methods: {
    reverseSideOpen() {
      this.sideOpened = !this.sideOpened;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/Header/header-pc.scss";
@import "@/assets/stylesheets/Header/header-mobile.scss";
</style>
