<template>
  <div id="profile">
    <div id="profile_top">
      <div id="top">
        <div id="top_subtitle" class="ja-title">プロフィール</div>
        <div id="top_title" class="en-title">Profile</div>
      </div>
      <div id="main">
        <div id="main_main">
          <div id="main_tag">山岳ランナー</div>
          <div id="main_name">
            <div id="main_name_ja" class="ja-title">上正原 真人</div>
            <div id="main_name_en" class="en-subtitle">Masato Kamishohara</div>
          </div>
          <div id="main_desc">
            <p>アメリカ生まれ、群馬県育ち。</p>
            <p>
              小学2年からサッカーを始め、プロサッカー選手を夢見る。高校はサッカー強豪校に入学するも、挫折を経験する。
            </p>
            <p>
              大学在学時に山岳ランニングに出会い、4年次にスカイランニング日本選手権優勝。
            </p>
            <p>以降大学卒業と同時にプロに転向し、世界舞台を目指す。</p>
          </div>
        </div>
        <div id="main_sub">
          <div id="main_profile">
            <div id="main_profile_line"></div>
            <div id="main_profile_content_wrapper">
              <div id="main_profile_content">
                <div class="main_profile_element">
                  <div class="main_profile_key">生年月日</div>
                  <div class="main_profile_value">1997 / 4 / 6</div>
                </div>
                <div class="main_profile_element">
                  <div class="main_profile_key">出身地</div>
                  <div class="main_profile_value">アメリカ メリーランド州</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="main_link">
          <div id="main_mountain" v-on:click="linkToMountain">
            <div id="main_mountain_doc">
              <div id="main_mountain_title" class="en-title">
                Mountain Addicts
              </div>
              <div id="main_mountain_desc">
                日本トップ選手が集まる山岳ランナーのプロチーム
              </div>
            </div>
            <i class="fas fa-chevron-circle-right fa-2x"></i>
          </div>
          <div id="main_unlim" v-on:click="linkToUnlim">
            <div id="main_unlim_doc">
              <div id="main_unlim_title" class="en-title">Unlim</div>
              <div id="main_unlim_desc">上正原真人をもっと応援しよう！</div>
            </div>
            <i class="fas fa-chevron-circle-right fa-2x"></i>
          </div>
        </div>
      </div>
    </div>
    <div id="result">
      <div id="result_top">
        <div class="ja-title" id="result_title_sub">過去の成績</div>
        <div class="en-title" id="result_title">Result</div>
      </div>
      <div id="result_main">
        <img
          src="/assets/images/Kpjt_top_result.jpg"
          alt="過去の成績"
          id="result_main_image"
        />
        <img
          src="/assets/images/TOP_result_iPhone.svg"
          alt="過去の成績"
          id="result_main_mob_image"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  },
  methods: {
    linkToMountain() {
      window.open("https://www.facebook.com/mountain.addicts.japan/", "_blank");
    },
    linkToUnlim() {
      window.open("https://unlim.team/athletes/masato.kamishouhara", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/stylesheets/Profile/profile-pc.scss";
@import "src/assets/stylesheets/Profile/profile-tab.scss";
@import "src/assets/stylesheets/Profile/profile-mobile.scss";
</style>
